import Q from 'q';
import ListTimer from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/list-timer'
import buyer_dom_manage_repairList from '@/lib/data-service/flight/buyer_dom_manage_repairList'
import scrollTop from '@/lib/common-service/scrollTop'
import buyer_dom_manage_repairCount from '@/lib/data-service/flight/buyer_dom_manage_repairCount'

export default {
    data() {
        return {
            searchForm: {
                orderNo: '',//订单号
                repOrderNo: '',//补退单号
                ticketNumber: ``,//票号
                psgName: ``,//乘客姓名
                appTimeBegin: ``,//申请日期开始
                appTimeEnd: ``,//申请日期结束
                repStatus: 1,// 补退订单状态: 1-全部 2-待处理 3-补退成功 4-补退驳回
                currentPage: `1`,//当前页码
                pageSize: '10'//每页记录条数
            },
            appTime: [],//申请日期
            pageResult: {
                currentPage: 1,
                pageSize: 10,
                pageData: [],
                totalCount: 0,
            },
            recordCountList: [],
            isFirst: true,
            loading: true,
            NavItemActive: 0,
            pCurrentPage: 1,

            deferInitDataCompleted: '',
            deferResolve: '',
            aListTimer: null,
        };
    },
    methods: {
        //初始化
        async init() {
            const __this = this;
            __this.__btn_reset_click();
        },

        //获取列表数据
        async __data_bind_list() {
            const __this = this;
            let data = this.searchForm;
            __this.loading = true;
            try {
                buyer_dom_manage_repairCount(data).then(res => {
                    const list = res.records;
                    this.recordCountList = list;
                    if (this.isFirst) {
                        this.isFirst = false;
                        const status = Number(__this.$route.query.status);
                        if (status) {
                            for (let i = 0; i < list.length; i++) {
                                const item = list[i];
                                if (item.status === status) {
                                    __this.__btn_tab_click(item, i);
                                    return;
                                }
                            }
                        }
                    }
                    buyer_dom_manage_repairList(data).then((result) => {
                        __this.loading = false;
                        let pageData = result.pageResult.pageData;
                        __this.pageResult.pageData = pageData;
                        __this.pageResult.totalCount = result.pageResult.totalCount;
                        __this.deferResolve()
                        scrollTop(500)
                    }).catch((err) => {
                        __this.loading = false;
                    });
                })
            } catch {
                __this.loading = false;
                __this.pageResult.pageData = []
                __this.pageResult.totalCount = 0
            }
        },

        //查看详情
        toDetails(repOrderNo) {
            this.$router.push({
                name: 'air-ticket-admin-supplement-refund-order-detail',
                query: {
                    repOrderNo
                }
            })
        },

        //查询
        __btn_search_click() {
            this.searchForm.currentPage = 1;
            this.__data_bind_list();
        },

        //重置
        __btn_reset_click() {
            let repStatus = this.searchForm.repStatus;
            this.searchForm = {
                orderNo: '',//订单号
                repOrderNo: '',//补退单号
                ticketNumber: ``,//票号
                psgName: ``,//乘客姓名
                appTimeBegin: ``,//申请日期开始
                appTimeEnd: ``,//申请日期结束
                repStatus,
                currentPage: `1`,//当前页码
                pageSize: '10'//每页记录条数
            };
            this.__data_bind_list();
        },

        //换tab
        __btn_tab_click(pItem, pIndex) {
            const __this = this;
            __this.searchForm.repStatus = pItem.status;
            __this.NavItemActive = pIndex;
            this.pageResult.currentPage = 1
            __this.__btn_search_click();
        },

        //修改申请日期
        changeAppTime(val) {
            this.searchForm.appTimeBegin = val ? val[0] + ' 00:00:00' : '';
            this.searchForm.appTimeEnd = val ? val[1] + ' 23:59:59' : '';
            this.__btn_search_click();
        },

        //换页
        __el_pagination_current_change_event_handler(pCurrentPage) {
            const __this = this;
            __this.pageResult.currentPage = pCurrentPage
            __this.__data_bind_list();
        },

        //切换每页最多显示数据条数
        __el_pagination_size_change_event_handler(pPageSize) {
            const __this = this;
            __this.pageResult.pageSize = pPageSize
            __this.__data_bind_list();
        },

        //
        _initTimer() {
            const __this = this
            let deferInitDataCompleted = this.deferInitDataCompleted
            deferInitDataCompleted.then(res => {
                const aListTimer = new ListTimer().init({
                    interval: `minute`,
                    get_data_source() {
                        const entity_list = __this.pageResult.pageData;

                        const d = {
                            entity_list,
                        };
                        return Q.resolve(d);
                    },
                    handler(args) {
                        const entity = args.entity;
                        const index_entity = args.index_entity;
                        const index_setInterval = args.index_setInterval
                        const utils = args.utils;
                        const get_process_data_101 = utils.get_process_data_101
                        if (index_setInterval === 0) {
                            return
                        }
                        if (entity.ticketEfficiency === '' || entity.ticketEfficiency === null) {
                            entity.ticketEfficiencyResult = ''
                        } else if (entity.ticketEfficiencyStatus === 3) {
                            entity.ticketEfficiencyResult = entity.ticketEfficiency
                        } else if (entity.ticketEfficiencyStatus === 2) {
                            entity.ticketEfficiencyResult++
                        } else if (entity.ticketEfficiencyStatus === 1) {
                            entity.ticketEfficiencyResult = ''
                        }
                    },
                });

                __this.aListTimer = aListTimer
            })
        },
    },
    created() {
        this.deferInitDataCompleted = new Promise((resolve, reject) => {
            this.deferResolve = resolve
        })
    },
    async mounted() {
        this.init();
        this._initTimer()
    },
    activated() {

    },
    deactivated() {
        if (this.aListTimer) {
            this.aListTimer.clear()
        }
    },
}
