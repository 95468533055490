const __request = require('./__request/flight-admin/__request_contentType_json');
const request = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/manage/repairList',
    data: data
  }
  return __request(pParameter)
}
export default request
